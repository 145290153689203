import React from "react";
import { useNavigate } from "react-router-dom";

import { Building, ChevronRight, UserSquare, Globe } from "lucide-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Anchor } from "DS/custom/anchor";
import Skeleton from "DS/custom/skeleton";
import { Breadcrumb } from "DS/custom/breadcrumb";
import { Card, CardContent } from "DS/custom/card";

/** @internal */
export const QUERY = gql`
  query AddSupplier {
    buyer {
      id
      hasPfSettingCategories
      hasInternationalSettingCategories
    }
  }
`;

const SKELETON = (
  <div className="flex max-w-3xl flex-col gap-4">
    <Skeleton className="h-8 w-48" />
    <Skeleton className="h-8 w-56" />
    <Skeleton className="h-24 w-full" repeat={3} />
  </div>
);

function AddSupplier() {
  const navigate = useNavigate();

  const { loading, data } = useQuery(QUERY, {
    onCompleted: ({ buyer }) => {
      const shouldNavigate = !buyer.hasPfSettingCategories && !buyer.hasInternationalSettingCategories;

      if (shouldNavigate) navigate("pj");
    },
  });

  if (loading) return SKELETON;

  const { buyer } = data;

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação"]} />

      <Text weight="medium">Escolha o tipo do seu fornecedor</Text>

      <Anchor to="pj" className="w-full hover:no-underline">
        <Card className="max-w-3xl">
          <CardContent className="flex flex-row items-center gap-4">
            <div className="rounded-md bg-slate-50 p-2">
              <Building className="h-5 w-5" />
            </div>

            <div className="flex-grow">
              <Text className="font-medium">Nacional Pessoa Jurídica (CNPJ)</Text>
              <Text>Cadastro de uma empresa brasileira.</Text>
            </div>
            <ChevronRight className="h-5 w-5" />
          </CardContent>
        </Card>
      </Anchor>

      {buyer.hasPfSettingCategories ? (
        <Anchor to="pf" className="w-full hover:no-underline">
          <Card className="max-w-3xl">
            <CardContent className="flex flex-row items-center gap-4">
              <div className="rounded-md bg-slate-50 p-2">
                <UserSquare className="h-5 w-5" />
              </div>

              <div className="flex-grow">
                <Text className="font-medium">Nacional Pessoa Física (CPF)</Text>
                <Text>Cadastro de um indivíduo brasileiro.</Text>
              </div>
              <ChevronRight className="h-5 w-5" />
            </CardContent>
          </Card>
        </Anchor>
      ) : null}

      {buyer.hasInternationalSettingCategories ? (
        <Anchor to="international" className="w-full hover:no-underline">
          <Card className="max-w-3xl">
            <CardContent className="flex flex-row items-center gap-4">
              <div className="rounded-md bg-slate-50 p-2">
                <Globe className="h-5 w-5" />
              </div>

              <div className="flex-grow">
                <Text className="font-medium">Internacional (TIN)</Text>

                <Text>Cadastro de uma empresa de outro país.</Text>
              </div>
              <ChevronRight className="h-5 w-5" />
            </CardContent>
          </Card>
        </Anchor>
      ) : null}
    </div>
  );
}

export default AddSupplier;
