import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Menu } from "lucide-react";

import { Box } from "grommet";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Button } from "DS/ui/button";

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "DS/ui/dropdown-menu";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import FormInfoForm from "Organisms/UBuyer/Settings/BuyerDocuments/FormInfoForm";
import DuplicateSettingBuyerDocument from "Organisms/UBuyer/Settings/BuyerDocuments/DuplicateSettingBuyerDocument";

/** @internal */
export const QUERY = gql`
  query FormInfo($buyerId: ID!, $settingBuyerDocumentId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingBuyerDocument(id: $settingBuyerDocumentId) {
        id
        name
        hint
        risk
        options
        fieldType
        updatedAt
        displayName
      }
    }
  }
`;

function FormInfo() {
  const navigate = useNavigate();
  const { buyerId, settingBuyerDocumentId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingBuyerDocumentId },
  });

  if (loading) return <GlobalLoading />;

  const { buyer } = data;
  const { settingBuyerDocument } = buyer;

  return (
    <Box
      onFocus={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      className="p-4 lg:px-10 lg:py-6"
    >
      <Box direction="row" justify="between" align="center">
        <Breadcrumb lastPage="Solicitados ao comprador" currentPage={buyer.settingBuyerDocument.displayName} />

        <Box direction="row" gap="small">
          <div className="flex flex-row gap-4">
            <OwnerChecker>
              <Button type="button" onClick={() => navigate("edit")}>
                Editar
              </Button>
            </OwnerChecker>

            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="p-2" aria-label="secondary-menu">
                  <Menu className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-56">
                <OwnerChecker>
                  <DuplicateSettingBuyerDocument />
                </OwnerChecker>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Box>
      </Box>

      <FormInfoForm settingBuyerDocument={settingBuyerDocument} />
    </Box>
  );
}

export default FormInfo;
