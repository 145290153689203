import React from "react";

import { Building, UserSquare, Globe, ChevronRight } from "lucide-react";

import { Anchor } from "DS/custom/anchor";
import { Text } from "DS/custom/text";
import { Heading } from "DS/custom/heading";
import { Card, CardContent } from "DS/custom/card";

import { NATIONAL_PJ, NATIONAL_PF, INTERNATIONAL, CATEGORY_OPTIONS_LABEL } from "Config/LegalEntity";

import { Breadcrumb } from "DS/custom/breadcrumb";

function CategoryTypeSelector() {
  return (
    <div className="space-y-6 lg:px-10 lg:py-6">
      <Breadcrumb levels={["Categorias", "Nova categoria"]} />

      <div className="space-y-4">
        <div className="max-w-xl space-y-1.5">
          <Heading level={4}>Tipo de Fornecedor</Heading>
          <Text>
            Selecione o tipo de fornecedor da sua categoria. Lembre-se, essa informação não poderá ser alterada após a
            criação da categoria
          </Text>
        </div>

        <div>
          <Anchor to="pj" className="w-full hover:no-underline">
            <Card className="max-w-3xl p-4">
              <CardContent className="flex flex-row items-center gap-4">
                <div className="rounded-md bg-slate-50 p-2">
                  <Building className="h-5 w-5" />
                </div>

                <div className="flex-grow">
                  <Text className="font-medium">{CATEGORY_OPTIONS_LABEL[NATIONAL_PJ]}</Text>
                </div>

                <ChevronRight className="h-5 w-5" />
              </CardContent>
            </Card>
          </Anchor>
        </div>

        <div>
          <Anchor to="pf" className="w-full hover:no-underline">
            <Card className="max-w-3xl p-4">
              <CardContent className="flex flex-row items-center gap-4">
                <div className="rounded-md bg-slate-50 p-2">
                  <UserSquare className="h-5 w-5" />
                </div>

                <div className="flex-grow">
                  <Text className="font-medium">{CATEGORY_OPTIONS_LABEL[NATIONAL_PF]}</Text>
                </div>
                <ChevronRight className="h-5 w-5" />
              </CardContent>
            </Card>
          </Anchor>
        </div>

        <div>
          <Anchor to="international" className="w-full hover:no-underline">
            <Card className="max-w-3xl p-4">
              <CardContent className="flex flex-row items-center gap-4">
                <div className="rounded-md bg-slate-50 p-2">
                  <Globe className="h-5 w-5" />
                </div>

                <div className="flex-grow">
                  <Text className="font-medium">{CATEGORY_OPTIONS_LABEL[INTERNATIONAL]}</Text>
                </div>
                <ChevronRight className="h-5 w-5" />
              </CardContent>
            </Card>
          </Anchor>
        </div>
      </div>
    </div>
  );
}

export default CategoryTypeSelector;
