import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Label } from "DS/ui/label";
import { useToast } from "DS/ui/use-toast";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Badge } from "DS/custom/badge";
import { Card, CardHeader, CardTitle, CardContent } from "DS/custom/card";

import { Trash2 } from "lucide-react";

import EmptyList from "Organisms/UBuyer/Settings/Categories/EmptyList";
import DocumentRiskSelect from "Organisms/UBuyer/Settings/Categories/DocumentRiskSelect";

function parseSettingGroupers(settingGroupers, value) {
  const parsedSettingGroupers = [];

  _.each(settingGroupers, (settingGrouper) => {
    const filteredSettingDocuments = _.filter(settingGrouper.settingDocuments, ({ id }) => !!value[id]);

    if (!filteredSettingDocuments.length) return;

    parsedSettingGroupers.push({ ...settingGrouper, settingDocuments: filteredSettingDocuments });
  });

  return parsedSettingGroupers;
}

const DocumentsEditList = React.forwardRef(({ settingGroupers, value, onChange, name }, ref) => {
  const { toast } = useToast();

  const onSelectRisk = (risk, settingDocument) => {
    const parsedValue = { ...value };

    if (!parsedValue[settingDocument.id]) return;

    parsedValue[settingDocument.id] = risk;

    onChange({ target: { name, value: parsedValue } });
  };

  const onRemove = (settingDocument) => {
    const parsedValue = { ...value };

    delete parsedValue[settingDocument.id];

    onChange({ target: { name, value: parsedValue } });
  };

  const parsedGroupers = useMemo(() => parseSettingGroupers(settingGroupers, value), [settingGroupers, value]);

  return (
    <>
      <input name={name} type="hidden" ref={ref} value={value} />

      <div className="flex flex-col gap-3 divide-slate-100">
        {parsedGroupers.length ? null : <EmptyList />}

        {parsedGroupers.map((settingGrouper) => (
          <Card key={settingGrouper.id} value={settingGrouper.id}>
            <CardHeader>
              <CardTitle>{settingGrouper.displayName}</CardTitle>
            </CardHeader>

            <CardContent>
              {settingGrouper.settingDocuments.length ? null : (
                <div className="py-2 text-slate-400">Esse grupo não possui documentos</div>
              )}

              <div className="flex flex-col gap-2.5 divide-y">
                {settingGrouper.settingDocuments.map((settingDocument) => (
                  <div
                    key={settingDocument.id}
                    className="flex w-full flex-col gap-3 pt-2.5 text-sm font-medium first:pt-0 md:flex-row md:items-center"
                  >
                    <div className="flex flex-grow items-start gap-1.5 md:flex-row md:items-center">
                      <div className="flex flex-row flex-wrap items-start gap-1.5 text-black md:items-center md:justify-between">
                        <Label htmlFor={settingDocument.id}>
                          <Text size="small" weight="medium">
                            {settingDocument.displayName}
                          </Text>
                        </Label>

                        {settingDocument.type === "Settings::SettingForm" ? (
                          <Badge variant="secondary" className="w-fit align-top">
                            Enviado pelo fornecedor
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-grow flex-row gap-4 md:flex-grow-0 md:gap-2.5">
                      <DocumentRiskSelect
                        settingDocument={settingDocument}
                        risk={value[settingDocument.id]}
                        onSelectRisk={onSelectRisk}
                      />
                      <Button
                        type="button"
                        onClick={() => {
                          onRemove(settingDocument);
                          const description = "As alterações só serão aplicadas após salvar as modificações.";
                          toast({ description, title: "Documento arquivado" });
                        }}
                        variant="ghost"
                        size="icon"
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );
});

DocumentsEditList.defaultProps = { value: {} };

DocumentsEditList.propTypes = {
  value: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settingGroupers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      settingDocuments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          risk: PropTypes.number,
          displayName: PropTypes.string,
          availableForInternational: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default DocumentsEditList;
