import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { Tabs, TabsList, TabsTrigger } from "DS/custom/tabs";

function LayoutTabs({ routes }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const rootRoute = pathname.split("/")[4] || "supplier_fields";

  const onClick = (event, path, turbo) => {
    if (turbo) {
      event.preventDefault();
      window.location = path;
    } else {
      navigate(path);
    }
  };

  return (
    <div key={pathname} className="hidden flex-row items-center justify-between gap-4 px-4 lg:flex lg:px-6">
      <Tabs defaultValue={rootRoute}>
        <TabsList>
          {routes.map(({ key, value, turbo }) => (
            <Link to={`../${key}`} key={`fields-tabs-${key}`} onClick={(event) => onClick(event, key, turbo)}>
              <TabsTrigger value={key}>{value}</TabsTrigger>
            </Link>
          ))}
        </TabsList>
      </Tabs>
    </div>
  );
}

LayoutTabs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })).isRequired,
};

export default LayoutTabs;
