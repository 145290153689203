import React from "react";
import PropTypes from "prop-types";

import { useFormContext } from "react-hook-form";

import { Text } from "DS/custom/text";
import { Switch } from "DS/ui/switch";
import { Card, CardHeader, CardTitle, CardContent } from "DS/custom/card";

import { FormItem, FormField, FormLabel, FormControl, FormMessage } from "DS/ui/form";

import formatDate from "Services/DateToString";

function CategoryFormAdvanced({ settingCategory }) {
  const { control } = useFormContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Configurações da categoria</CardTitle>
      </CardHeader>
      <CardContent>
        <FormField
          control={control}
          name="keepActiveOnSupplierReview"
          render={({ field }) => (
            <FormItem className="space-y-1">
              <Text color="secondary">Homologação aprovada</Text>

              <div className="flex flex-row items-center gap-2">
                <FormControl>
                  <Switch checked={field.value} onCheckedChange={(checked) => field.onChange(checked)} />
                </FormControl>

                <FormLabel>{field.value ? "Conectar fornecedor" : "Desconectar fornecedor"}</FormLabel>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <Text color="secondary">Tipo de Fornecedor</Text>
          <Text weight="medium">{settingCategory.legalEntity}</Text>
        </div>

        <div>
          <Text color="secondary">Data da criação</Text>
          <Text weight="medium">{formatDate(settingCategory.createdAt)}</Text>
        </div>

        <div>
          <Text color="secondary">Data da última edição</Text>
          <Text weight="medium">{formatDate(settingCategory.updatedAt)}</Text>
        </div>
      </CardContent>
    </Card>
  );
}

CategoryFormAdvanced.propTypes = {
  settingCategory: PropTypes.shape({
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    legalEntity: PropTypes.string,
    keepActiveOnSupplierReview: PropTypes.bool,
  }).isRequired,
};

export default CategoryFormAdvanced;
