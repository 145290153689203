import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Heading } from "DS/custom/heading";

function SuppliersLayout() {
  const { pathname } = useLocation();

  return (
    <div key={pathname} className="flex flex-col overflow-auto">
      <div className="flex flex-row items-center justify-between gap-4 border-b px-6 py-6">
        <Heading>Fornecedores</Heading>
      </div>

      <div className="flex flex-grow flex-col p-4 lg:p-10 lg:pb-20">
        <Outlet />
      </div>
    </div>
  );
}

export default SuppliersLayout;
