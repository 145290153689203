import React from "react";

import { Button } from "DS/ui/button";

import { MoreHorizontal } from "lucide-react";

import { DropdownMenu, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from "DS/ui/dropdown-menu";

import PropTypes from "prop-types";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import CreateSettingFormReportCsv from "Organisms/UBuyer/Settings/Forms/CreateSettingFormReportCsv";
import FormDelete from "Organisms/UBuyer/Settings/Forms/FormDelete";
import EditFormMenuItem from "Organisms/UBuyer/Settings/Forms/EditFormMenuItem";
import NewRequestedFormDialog from "Organisms/UBuyer/Settings/Forms/NewRequestedFormDialog";
import DuplicateFormMenuItem from "Organisms/UBuyer/Settings/Forms/DuplicateFormMenuItem";

function FormInfoMenu({ buyerId, settingFormId }) {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild className="bg-muted border-none">
        <Button variant="outline" className="p-0 h-0" aria-label="secondary-menu">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        <OwnerChecker>
          <EditFormMenuItem buyerId={buyerId} settingFormId={settingFormId} />
        </OwnerChecker>

        <CreateSettingFormReportCsv settingFormId={settingFormId} />

        <OwnerChecker>
          <DuplicateFormMenuItem buyerId={buyerId} settingFormId={settingFormId} />
          <NewRequestedFormDialog buyerId={buyerId} settingFormId={settingFormId} />
          <DropdownMenuSeparator />
          <FormDelete buyerId={buyerId} settingFormId={settingFormId} />
        </OwnerChecker>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

FormInfoMenu.propTypes = {
  buyerId: PropTypes.string.isRequired,
  settingFormId: PropTypes.string.isRequired,
};

export default FormInfoMenu;
