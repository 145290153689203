import React, { useState } from "react";

import gql from "graphql-tag";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";

import { Archive } from "lucide-react";

import { DropdownMenuItem } from "DS/ui/dropdown-menu";

import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { Button } from "DS/ui/button";

import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
} from "DS/ui/alert-dialog";

/** @internal */
export const MUTATION = gql`
  mutation FormDelete($settingFormId: ID!) {
    deleteSettingForm(input: { settingFormId: $settingFormId }) {
      buyer {
        id
        settingForms {
          id
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function FormDelete({ buyerId, settingFormId }) {
  const { toast } = useToast();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [deleteSettingForm, { loading }] = useMutation(MUTATION);

  const onClick = async () => {
    const { data } = await deleteSettingForm({ variables: { settingFormId } });
    const { errors } = data.deleteSettingForm;

    if (errors.length) {
      setShowAlertDialog(false);

      toast({
        title: "Não foi possível excluir o campo!",
        description: errors[0].message,
        variant: "destructive",
      });
    } else {
      setShowAlertDialog(false);
      toast({ title: "Campo excluído com sucesso!" });
      window.location.assign(`/buyers/${buyerId}/settings/fields/supplier_fields`);
    }
  };

  return (
    <AlertDialog open={showAlertDialog}>
      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
        <AlertDialogTrigger
          className="flex flex-row items-center"
          onClick={() => setShowAlertDialog(true)}
          disabled={loading}
        >
          <Text color="secondary">
            <Archive strokeWidth={3} className="mr-2 h-4 w-4 text-red-600" />
          </Text>
          <Text weight="medium" className="text-red-600">
            Excluir documento
          </Text>
        </AlertDialogTrigger>
      </DropdownMenuItem>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle asChild>
            <Heading>Esta ação exclui este item permanentemente</Heading>
          </AlertDialogTitle>

          <AlertDialogDescription asChild>
            <Text size="small" weight="medium">
              Se você quer apenas remover este item em uma categoria, arquive-o diretamente na configuração da categoria
              desejada.
            </Text>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <Button
            type="button"
            onClick={onClick}
            variant="destructive"
            disabled={loading}
            aria-label="Excluir documento"
          >
            Entendi, mas quero excluir!
          </Button>

          <Button type="button" variant="outline" disabled={loading} onClick={() => setShowAlertDialog(false)}>
            Cancelar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

FormDelete.propTypes = {
  buyerId: PropTypes.string.isRequired,
  settingFormId: PropTypes.string.isRequired,
};

export default FormDelete;
