import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { Copy } from "lucide-react";

import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import { DropdownMenuItem } from "DS/ui/dropdown-menu";

/** @internal */
export const MUTATION = gql`
  mutation DuplicateSettingBuyerDocument($settingBuyerDocumentId: ID!) {
    duplicateSettingBuyerDocument(input: { settingBuyerDocumentId: $settingBuyerDocumentId }) {
      settingBuyerDocument {
        id
        buyer {
          id
          settingBuyerDocuments {
            id
            displayName
            risk
            fieldType
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

function DuplicateSettingBuyerDocument() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { settingBuyerDocumentId } = useParams();
  const [duplicateSettingBuyerDocument, { loading }] = useMutation(MUTATION);

  const onSelect = async () => {
    const variables = { settingBuyerDocumentId };
    const { data } = await duplicateSettingBuyerDocument({ variables });

    const { settingBuyerDocument, errors } = data.duplicateSettingBuyerDocument;
    if (errors.length)
      return toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao duplicar o documento",
      });

    toast({
      title: "Documento duplicado",
      description: "O documento foi duplicado com sucesso!",
    });

    return navigate(`../${settingBuyerDocument.id}/edit`);
  };

  return (
    <DropdownMenuItem onSelect={onSelect} disabled={loading}>
      <Text color="secondary">
        <Copy strokeWidth={3} className="mr-2 h-4 w-4 " />
      </Text>

      <Text weight="medium" color="secondary">
        Duplicar
      </Text>
    </DropdownMenuItem>
  );
}

export default DuplicateSettingBuyerDocument;
