import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import _ from "lodash";

import { CirclePlus, Book } from "lucide-react";

import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import { Text } from "DS/custom/text";
import Skeleton from "DS/custom/skeleton";

import SearchInput from "Organisms/UBuyer/Shared/SearchInput/SearchInput";
import EmptyList from "Assets/settings/shared/EmptyList.png";
import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import CategoriesTable from "Organisms/UBuyer/Settings/Categories/CategoriesTable";
import CategoryReportExperimentDropdown from "Organisms/UBuyer/Settings/Categories/CategoryReportExperimentDropdown";

import createSafeRegExp from "Services/Search";

/** @internal */
export const QUERY = gql`
  query categoriesList($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingCategoriesEnabled {
        id
        name
        updatedAt
        displayName
        legalEntity
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <Skeleton className="h-6 w-80" />
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-12 w-[543px]" />
      <Skeleton className="h-10 w-80" />
    </div>
    <Skeleton className="h-10 w-full" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function CategoriesList() {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [term, setTerm] = useState("");
  const regExp = createSafeRegExp(term);

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId },
  });

  if (loading) return SKELETON;

  const { settingCategoriesEnabled } = data.buyer;

  const filteredSettingCategories = settingCategoriesEnabled.filter(({ displayName }) =>
    regExp.test(_.deburr(displayName)),
  );

  return (
    <div>
      <div className="hidden lg:flex flex-col md:flex-row gap-4 px-4 py-2 lg:py-6 lg:px-10 border-b">
        <div className="space-y-1 flex-grow">
          <Heading>Categorias</Heading>
          <Text size="small">
            Gerencie os documentos solicitados na homologação e suas respectivas pontuações no Score de Risco.
          </Text>
        </div>
        <a href="https://suporte.linkana.com/pt-BR/articles/8719028-como-configurar-uma-categoria">
          <Button variant="outline" className="space-x-2">
            <Book size={20} /> <span>Aprenda a configurar Categorias</span>
          </Button>
        </a>
      </div>

      <div className="flex flex-col gap-6 p-4 lg:p-10">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col-reverse gap-2 lg:flex-row justify-between">
            <SearchInput
              value={term}
              placeholder="Encontre uma categoria..."
              onChange={(value) => {
                setTerm(_.deburr(value));
              }}
            />

            <div className="flex flex-row items-center gap-4 justify-end lg:justify-normal">
              <OwnerChecker>
                <Button type="button" size="sm" onClick={() => navigate("new")} className="space-x-2">
                  <CirclePlus size={14} />
                  <span>Criar nova categoria</span>
                </Button>
              </OwnerChecker>
              <CategoryReportExperimentDropdown />
            </div>
          </div>

          {settingCategoriesEnabled.length ? (
            <CategoriesTable categories={filteredSettingCategories} />
          ) : (
            <div className="flex flex-col items-center justify-center gap-4">
              <img alt="Empty List" src={EmptyList} className="w-32" />
              <Text>Você ainda não criou nenhuma categoria dentro da Linkana.</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoriesList;
