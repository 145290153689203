import React, { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import z from "zod";
import { useForm } from "react-hook-form";
import { REQUIRED_STRING, OPTIONAL_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Heading } from "DS/custom/heading";
import { Textarea } from "DS/ui/textarea";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import SettingGrouperFormField from "Organisms/UBuyer/Settings/Forms/SettingGrouperFormField";
import FormAdvancedFormFields from "Organisms/UBuyer/Settings/Forms/FormAdvancedFormFields";
import FormQuestionsFormFields from "Organisms/UBuyer/Settings/Forms/FormQuestionsFormFields";
import FormFieldAlertDialog from "Organisms/UBuyer/Settings/Forms/FormFieldAlertDialog";

export const SETTING_FORM_FIELD = z.object({
  hint: OPTIONAL_STRING,
  type: REQUIRED_STRING,
  name: OPTIONAL_STRING,
  optional: z.boolean().default(false),
  display_name: REQUIRED_STRING,
  options: z.array(z.object({ displayName: REQUIRED_STRING })).optional(),
});

const formSchema = z.object({
  hint: OPTIONAL_STRING,
  expirable: z.boolean(),
  displayName: REQUIRED_STRING,
  settingGrouperId: REQUIRED_STRING,
  fields: z.array(SETTING_FORM_FIELD).min(1, "É obrigatório criar ao menos uma pergunta"),
  triggerWebhookAfterExpiredReview: z.boolean(),
});

/** @internal */
export const defaultValues = {
  hint: "",
  fields: [],
  displayName: "",
  expirable: false,
  settingGrouperId: "",
  triggerWebhookAfterExpiredReview: false,
};

function FormNewForm({ onSubmit, submitting }) {
  const navigate = useNavigate();
  const [openFormFieldDialog, setOpenFormFieldDialog] = useState(false);
  const [formFieldIndex, setFormFieldIndex] = useState(undefined);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onEditFormField = (index) => {
    setFormFieldIndex(index);
    setOpenFormFieldDialog(true);
    form.clearErrors("fields");
  };

  return (
    <>
      <Form {...form}>
        <form id="FormNewForm" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-8">
          <div>
            <div className="space-y-4 border-b border-slate-100 pb-5">
              <Heading>Documento</Heading>

              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem className="flex flex-row gap-8">
                    <div className="flex w-full max-w-3xl flex-col gap-2 text-sm">
                      <FormLabel className="font-medium" htmlFor="displayName">
                        Nome
                      </FormLabel>

                      <FormControl>
                        <Input id="displayName" placeholder="Digite um nome para o documento..." {...field} />
                      </FormControl>

                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="hint"
                render={({ field }) => (
                  <FormItem className="flex flex-row gap-8">
                    <div className="flex w-full max-w-3xl flex-col gap-2 text-sm">
                      <FormLabel className="font-medium">
                        Descrição{" "}
                        <Text size="small" color="secondary" as="span">
                          (opcional)
                        </Text>
                      </FormLabel>

                      <FormControl>
                        <Textarea
                          id="hint"
                          className="h-40 min-h-[80px]"
                          placeholder="Insira dicas de como preencher corretamente este documento..."
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <SettingGrouperFormField form={form} />
              <FormAdvancedFormFields form={form} />
            </div>

            <div className="pt-5">
              <FormQuestionsFormFields form={form} onEditFormField={onEditFormField} />
              <p className="text-sm font-medium text-destructive">{form.formState.errors?.fields?.message}</p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <Button form="FormNewForm" type="submit" disabled={submitting}>
              Criar
            </Button>

            <Button type="button" variant="outline" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
          </div>
        </form>
      </Form>

      {openFormFieldDialog ? (
        <FormFieldAlertDialog
          onClose={() => setOpenFormFieldDialog(false)}
          mainForm={form}
          formFieldIndex={formFieldIndex}
        />
      ) : null}
    </>
  );
}

FormNewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default FormNewForm;
