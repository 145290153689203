import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";

import { Box, Text, Form } from "grommet";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";

import { Button } from "DS/ui/button";

import OwnerChecker from "Organisms/Shared/Authorization/OwnerChecker";
import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import FormInfoMenu from "Organisms/UBuyer/Settings/Forms/FormInfoMenu";

/** @internal */
export const QUERY = gql`
  query FormInfo($buyerId: ID!, $settingFormId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForm(id: $settingFormId) {
        id
        name
        hint
        fields
        expirable
        displayName
      }
    }
  }
`;

function FormInfo() {
  const navigate = useNavigate();
  const { settingFormId, buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, {
    variables: { buyerId, settingFormId },
  });

  if (loading) return <GlobalLoading />;

  const { settingForm } = data.buyer;

  return (
    <Box
      onFocus={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      className="max-w-7xl p-4 lg:px-10 lg:py-6"
    >
      <Box direction="row" justify="between" align="center">
        <Breadcrumb lastPage="Documentos" currentPage={settingForm.displayName} />

        <Box direction="row" gap="small">
          <div className="flex flex-row gap-4">
            <OwnerChecker>
              <Button type="button" onClick={() => navigate("edit")}>
                Editar
              </Button>
            </OwnerChecker>

            <FormInfoMenu />
          </div>
        </Box>
      </Box>

      <Box margin={{ top: "small" }}>
        <Form>
          <Box margin={{ vertical: "small" }}>
            <Text size="small" color="dark-3" dangerouslySetInnerHTML={{ __html: settingForm.hint }} />
          </Box>

          {_.sortBy(settingForm.fields, "order").map((field) => (
            <div key={field.order} className="mb-2 flex flex-col p-4 border rounded-md">
              <Text size="small">
                {field.display_name} {field.optional ? " (opcional)" : ""}
              </Text>

              <Text size="small" color="dark-3" dangerouslySetInnerHTML={{ __html: field.hint }} />

              {field.options && field.options.length > 0
                ? field.options.map((option) => (
                    <Text size="small" key={field.order + option}>
                      {option}
                    </Text>
                  ))
                : null}
            </div>
          ))}
        </Form>
      </Box>
    </Box>
  );
}

export default FormInfo;
