import React from "react";
import { useNavigate } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";

import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { REQUIRED_STRING, REQUIRED_CNPJ } from "Config/Zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { MaskedInput } from "DS/ui/maskedInput";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

const ZOD_RESOLVER = zodResolver(z.object({ name: REQUIRED_STRING, identifier: REQUIRED_CNPJ }));

function AddPJSupplierManually() {
  const navigate = useNavigate();

  const form = useForm({
    resolver: ZOD_RESOLVER,
    defaultValues: { identifier: "", name: "" },
  });

  const onSubmit = ({ name, identifier }) => {
    const params = new URLSearchParams({ name, identifier });
    navigate(`form?${String(params)}`);
  };

  return (
    <div className="space-y-4">
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação", "Nacional Pessoa Jurídica (CNPJ)"]} />

      <Text weigh="medium" className="mt-2">
        Digite os dados do seu fornecedor...
      </Text>

      <FormProvider {...form}>
        <form id="AddPJSupplierManually" className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex w-full max-w-xl flex-col gap-3">
              <FormField
                name="identifier"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-medium">CNPJ</FormLabel>

                    <FormControl>
                      <MaskedInput mask="_" {...field} format="##.###.###/####-##" placeholder="Digite um CNPJ..." />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="name"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-medium">Razão social</FormLabel>

                    <FormControl>
                      <Input {...field} placeholder="Digite uma Razão social..." />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="space-x-4">
            <Button type="submit">Selecionar fornecedor</Button>

            <Button type="button" variant="outline" onClick={() => navigate("./..")}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default AddPJSupplierManually;
