import React from "react";
import PropTypes from "prop-types";
import { Anchor } from "DS/custom/anchor";
import { ChevronRight } from "lucide-react";

// TODO: MOVE TO DS
function Breadcrumb({ firstPage, lastPage, currentPage, lastPageUrl }) {
  return (
    <div className="flex flex-row items-center gap-1">
      {firstPage ? (
        <>
          <Anchor to="./../.." className="font-medium no-underline">
            {firstPage}
          </Anchor>

          <ChevronRight className="text-sm text-slate-400" />
        </>
      ) : null}

      {lastPageUrl ? (
        <Anchor onClick={() => window.location.assign(lastPageUrl)} className="font-medium no-underline">
          {lastPage}
        </Anchor>
      ) : (
        <Anchor to="./.." className="font-medium no-underline">
          {lastPage}
        </Anchor>
      )}

      <ChevronRight className="text-sm text-slate-400" />

      <p className="text-sm font-semibold leading-6 text-gray-900">{currentPage}</p>
    </div>
  );
}

Breadcrumb.defaultProps = { firstPage: "", lastPageUrl: "" };

Breadcrumb.propTypes = {
  firstPage: PropTypes.string,
  lastPageUrl: PropTypes.string,
  lastPage: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default Breadcrumb;
