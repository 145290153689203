import React, { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate, useParams } from "react-router-dom";

import { AlertTriangle } from "lucide-react";

import z from "zod";
import { useForm } from "react-hook-form";
import { REQUIRED_STRING, OPTIONAL_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Textarea } from "DS/ui/textarea";
import { Heading } from "DS/custom/heading";
import { Alert, AlertDescription, AlertTitle } from "DS/ui/alert";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import SettingGrouperFormField from "Organisms/UBuyer/Settings/Forms/SettingGrouperFormField";
import FormAdvancedFormFields from "Organisms/UBuyer/Settings/Forms/FormAdvancedFormFields";
import FormQuestionsFormFields from "Organisms/UBuyer/Settings/Forms/FormQuestionsFormFields";
import FormFieldAlertDialog from "Organisms/UBuyer/Settings/Forms/FormFieldAlertDialog";

const SETTING_FORM_FIELD = z.object({
  hint: OPTIONAL_STRING,
  type: REQUIRED_STRING,
  name: OPTIONAL_STRING,
  optional: z.boolean().default(false),
  display_name: REQUIRED_STRING,
  options: z.array(z.object({ displayName: REQUIRED_STRING })).optional(),
});

const formSchema = z.object({
  hint: OPTIONAL_STRING,
  expirable: z.boolean(),
  displayName: REQUIRED_STRING,
  settingGrouperId: REQUIRED_STRING,
  fields: z.array(SETTING_FORM_FIELD).min(1, "É obrigatório criar ao menos uma pergunta"),
  triggerWebhookAfterExpiredReview: z.boolean(),
});

function FormEditForm({ onSubmit, submitting, formInitialValues }) {
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [openFormFieldDialog, setOpenFormFieldDialog] = useState(false);
  const [formFieldIndex, setFormFieldIndex] = useState(undefined);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: formInitialValues,
  });

  const onEditFormField = (index) => {
    setFormFieldIndex(index);
    setOpenFormFieldDialog(true);
    form.clearErrors("fields");
  };

  return (
    <>
      <Form {...form}>
        <form
          id="FormEditForm"
          onSubmit={form.handleSubmit(onSubmit)}
          className="ap-8 flex flex-col p-4 lg:px-10 lg:py-6"
        >
          <div className="flex flex-row items-center justify-between gap-8">
            <Breadcrumb
              firstPage="Documentos"
              lastPage={formInitialValues.displayName}
              lastPageUrl={`/buyers/${buyerId}/settings/fields/supplier_fields`}
              currentPage="Editar"
            />

            <div className="flex flex-row gap-4">
              <Button form="FormEditForm" type="submit" disabled={submitting}>
                Salvar
              </Button>

              <Button type="button" variant="outline" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
            </div>
          </div>

          <div className="space-y-4 border-b border-slate-100 pb-5">
            <Heading>Documento</Heading>

            <FormField
              control={form.control}
              name="displayName"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-3xl flex-col gap-2 text-sm">
                    <FormLabel className="font-medium" htmlFor="displayName">
                      Nome
                    </FormLabel>

                    <FormControl>
                      <Input id="displayName" placeholder="Digite um nome para o documento..." {...field} />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hint"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-3xl flex-col gap-2 text-sm">
                    <FormLabel className="font-medium">
                      Descrição{" "}
                      <Text size="small" color="secondary" as="span">
                        (opcional)
                      </Text>
                    </FormLabel>

                    <FormControl>
                      <Textarea
                        id="hint"
                        className="h-40 min-h-[80px]"
                        placeholder="Insira dicas de como preencher corretamente este documento..."
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <SettingGrouperFormField form={form} />
            <FormAdvancedFormFields form={form} />
          </div>

          <div className="pt-5">
            {formInitialValues.createdByTemplate ? (
              <Alert className="mb-4">
                <AlertTriangle className="h-4 w-4 flex-none" />
                <AlertTitle>Documento padrão</AlertTitle>

                <AlertDescription>
                  Não é possível adicionar ou remover perguntas de documentos padronizados.
                </AlertDescription>
              </Alert>
            ) : null}

            <FormQuestionsFormFields
              createdByTemplate={formInitialValues.createdByTemplate}
              form={form}
              onEditFormField={onEditFormField}
            />
            <p className="text-sm font-medium text-destructive">{form.formState.errors?.fields?.message}</p>
          </div>
        </form>
      </Form>

      {openFormFieldDialog ? (
        <FormFieldAlertDialog
          mainForm={form}
          createdByTemplate={formInitialValues.createdByTemplate}
          onClose={() => setOpenFormFieldDialog(false)}
          formFieldIndex={formFieldIndex}
        />
      ) : null}
    </>
  );
}

FormEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formInitialValues: PropTypes.shape({
    hint: PropTypes.string,
    expirable: PropTypes.bool,
    displayName: PropTypes.string,
    settingGrouperId: PropTypes.string,
    createdByTemplate: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    triggerWebhookAfterExpiredReview: PropTypes.bool,
  }).isRequired,
};

export default FormEditForm;
