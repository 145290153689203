import React from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Text } from "DS/custom/text";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { NATIONAL_PJ } from "Config/LegalEntity";
import formatCurrency from "Config/Currency";
import { formatCnpj, formatCnae } from "Services/RFFormatter";

import useSettingCategories from "Organisms/UBuyer/Companies/Index/AddSupplier/SettingCategory/useSettingCategories";

import BaseSupplierForm, { SKELETON } from "Organisms/UBuyer/Companies/Index/AddSupplier/BaseSupplierForm";

/** @internal */
export const QUERY = gql`
  query AddPJSupplierForm($cnpj: BigInt!) {
    branch: branchByCnpj(cnpj: $cnpj) {
      id
      mf
      porte
      situacao
      cnaeFiscal
      opcaoPeloMei
      capitalSocial
      cnaeFiscalTexto
      opcaoPeloSimples
      descricaoNaturezaJuridica
    }
  }
`;

function AddPJSupplierForm() {
  const [searchParams] = useSearchParams();
  const { name, identifier } = Object.fromEntries(searchParams);
  const { loading: loadingSC, settingCategories } = useSettingCategories();
  const { data, loading } = useQuery(QUERY, { variables: { cnpj: identifier } });

  if (loading || loadingSC) return SKELETON;

  if (!name || !identifier) return <Navigate to=".." />;

  const categories = settingCategories.filter(({ legalEntity }) => legalEntity === NATIONAL_PJ);

  const { branch } = data;

  const bgColor = branch.situacao === "Ativa" ? "bg-green-500" : "bg-red-500";

  return (
    <>
      <Breadcrumb
        levels={["Fornecedores", "Iniciar homologação", "Nacional Pessoa Jurídica (CNPJ)", "Dados da homologação"]}
      />

      <BaseSupplierForm categories={categories}>
        <div className="flex flex-col max-w-5xl gap-4 rounded-md border p-6 bg-zinc-50">
          <div className="flex flex-row items-center gap-2">
            <div className={`w-2 h-2 flex-none rounded-full ${bgColor}`} />

            <Text size="small" weight="medium">
              {name}
            </Text>

            <Text size="xsmall" color="disabled">
              ({formatCnpj(identifier)})
            </Text>
          </div>

          <div className="w-full border-b" />

          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <Text size="small" weight="medium" className="w-32 flex-none">
                CNAE principal
              </Text>

              <Text size="small">
                {formatCnae(branch.cnaeFiscal)} - {branch.cnaeFiscalTexto}
              </Text>
            </div>

            <div className="flex flex-row flex-wrap items-center gap-x-8 gap-y-2">
              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  Natureza jurídica
                </Text>

                <Text size="small">{branch.descricaoNaturezaJuridica}</Text>
              </div>

              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  Capital social
                </Text>

                <Text size="small">{formatCurrency(branch.capitalSocial)}</Text>
              </div>

              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  Porte
                </Text>

                <Text size="small">{branch.porte}</Text>
              </div>

              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  MEI
                </Text>

                <Text size="small">{branch.opcaoPeloMei ? "Sim" : "Não"}</Text>
              </div>

              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  Matriz
                </Text>

                <Text size="small">{branch.mf === "1" ? "Sim" : "Não"}</Text>
              </div>

              <div className="flex flex-col gap-1 w-72">
                <Text size="small" weight="medium">
                  Simples
                </Text>

                <Text size="small">{branch.opcaoPeloSimples}</Text>
              </div>
            </div>
          </div>
        </div>
      </BaseSupplierForm>
    </>
  );
}

export default AddPJSupplierForm;
