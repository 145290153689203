import React from "react";
import { useParams } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { CircleUser, Gauge, Building2, ListChecks, Store } from "lucide-react";

import Sidebar from "Organisms/UBuyer/Settings/Shared/Sidebar";
import MobileMenu from "Organisms/UBuyer/Settings/Shared/MobileMenu";
import Skeleton from "DS/custom/skeleton";

/** @internal */
export const QUERY = gql`
  query WebhooksSvix($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      svixApplicationKey
      auditLogOrganizationId
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6">
    <div className="flex flex-row flex-wrap items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="min-h-[1500px] w-full" />
    </div>
  </div>
);

function Navigation() {
  const { buyerId } = useParams();

  const { loading, data } = useQuery(QUERY, { variables: { buyerId } });

  if (loading) return SKELETON;

  const { buyer } = data;

  const groups = [
    {
      title: "Minha conta",
      Icon: CircleUser,
      items: [{ matchPath: /user\//g, turbo: true, label: "Perfil", path: `/buyers/${buyerId}/user/edit` }],
    },
    {
      title: "Empresa",
      Icon: Building2,
      items: [
        { matchPath: /edit/g, turbo: true, label: "Geral", path: `/buyers/${buyer.id}/edit` },
        { matchPath: /users/g, turbo: true, label: "Usuários", path: `/buyers/${buyer.id}/users/actives` },
        { matchPath: /integrations/g, turbo: true, label: "Integrações", path: `/buyers/${buyer.id}/integrations` },
      ],
    },
    {
      title: "Fornecedores",
      Icon: Store,
      items: [
        {
          matchPath: /fields/g,
          turbo: true,
          label: "Documentos",
          path: `/buyers/${buyerId}/settings/fields/supplier_fields`,
        },
        {
          matchPath: /setting_groupers/g,
          turbo: true,
          label: "Revisões",
          path: `/buyers/${buyerId}/setting_groupers`,
        },
        { matchPath: /setting_tags/g, turbo: true, label: "Marcadores", path: `/buyers/${buyerId}/setting_tags` },
        { matchPath: /advanced/g, turbo: false, label: "Config. avançadas", path: "advanced" },
      ],
    },
    {
      title: "Homologações",
      Icon: ListChecks,
      items: [
        { matchPath: /categories/g, turbo: false, label: "Categorias", path: "categories" },
        { matchPath: /workflows/g, turbo: false, label: "Aprovações", path: "workflows" },
      ],
    },
    {
      title: "Performance",
      Icon: Gauge,
      items: [{ matchPath: /performance_forms/g, turbo: false, label: "Questionários", path: "performance_forms" }],
    },
  ];

  if (buyer.svixApplicationKey)
    groups[2].items.splice(2, 0, {
      matchPath: /webhooks/g,
      turbo: false,
      label: "Webhooks",
      path: "webhooks",
    });

  if (buyer.auditLogOrganizationId)
    groups[1].items.splice(5, 0, {
      matchPath: /audit_logs/g,
      turbo: true,
      label: "Registros de auditoria",
      path: `/buyers/${buyer.id}/audit_logs`,
    });

  return (
    <div>
      <Sidebar groups={groups} />
      <MobileMenu groups={groups} />
    </div>
  );
}

export default Navigation;
