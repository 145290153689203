export const formatCnpj = (cnpj) => {
  const parsedCnpj = String(cnpj).replace(/\D/g, "").padStart(14, "0");

  const num1 = parsedCnpj.slice(0, 2);
  const num2 = parsedCnpj.slice(2, 5);
  const num3 = parsedCnpj.slice(5, 8);
  const num4 = parsedCnpj.slice(8, 12);
  const num5 = parsedCnpj.slice(12, 14);

  return `${num1}.${num2}.${num3}/${num4}-${num5}`;
};

export const formatCpf = (cpf) => {
  const parsedCpf = String(cpf).replace(/\D/g, "").padStart(11, "0");

  const num1 = parsedCpf.slice(0, 3);
  const num2 = parsedCpf.slice(3, 6);
  const num3 = parsedCpf.slice(6, 9);
  const num4 = parsedCpf.slice(9, 11);

  return `${num1}.${num2}.${num3}-${num4}`;
};

export const formatCnae = (cnae) => {
  const parsedCnae = String(cnae).replace(/\D/g, "").padStart(7, "0");

  const num1 = parsedCnae.slice(0, 4);
  const num2 = parsedCnae.slice(4, 5);
  const num3 = parsedCnae.slice(5, 7);

  return `${num1}-${num2}/${num3}`;
};
