import React, { useState, useRef } from "react";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { useToast } from "DS/ui/use-toast";

import MyAlertDialog from "Molecules/Layer/MyAlertDialog";
import GlobalLoading from "Molecules/Spinner/GlobalLoading";
import CategoryEditForm from "Organisms/UBuyer/Settings/Categories/CategoryEditForm";

/** @internal */
export const QUERY = gql`
  query categoryEdit($buyerId: ID!, $id: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingCategory(id: $id) {
        id
        createdAt
        updatedAt
        displayName
        legalEntity
        keepActiveOnSupplierReview
        activeSuppliersCount
        settingRisks {
          id
          settingDocument {
            id
            settingGrouper {
              id
            }
          }
          risk
        }
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation categoryEdit($id: ID!, $settingCategory: SettingCategoryInput!) {
    updateSettingCategory(input: { id: $id, settingCategory: $settingCategory }) {
      settingCategory {
        id
        displayName
        legalEntity
        keepActiveOnSupplierReview
        settingRisks {
          id
          settingDocument {
            id
            settingGrouper {
              id
            }
          }
          risk
        }

        buyer {
          id
          hasPfSettingCategories
          hasInternationalSettingCategories
          settingGroupers {
            id
            displayName
            settingDocuments {
              id
              displayName
              risk: defaultRisk
            }
          }
        }
      }
    }
  }
`;

function CategoryEdit() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const formValues = useRef(null);
  const { id, buyerId } = useParams();
  const [showMyAlertDialog, setShowMyAlertDialog] = useState(false);
  const { data, loading } = useQuery(QUERY, { variables: { buyerId, id } });

  const [updateSettingCategory, { loading: submitting }] = useMutation(MUTATION);

  if (loading) return <GlobalLoading />;

  const { settingCategory } = data.buyer;
  const { keepActiveOnSupplierReview } = settingCategory;

  const onSubmit = async () => {
    const settingRisksAttributes = [];
    const { settingDocuments, ...rest } = formValues.current;

    _.forEach(settingDocuments, (risk, settingDocumentId) => {
      settingRisksAttributes.push({ risk: Number(risk), settingDocumentId });
    });

    await updateSettingCategory({
      variables: { id, settingCategory: { ...rest, settingRisksAttributes } },
    });

    toast({ title: "Categoria editada com sucesso!" });
    navigate("./../");
  };

  const handleSubmit = (values) => {
    formValues.current = values;

    if (values.keepActiveOnSupplierReview === keepActiveOnSupplierReview) onSubmit();
    else setShowMyAlertDialog(true);
  };

  return (
    <div className="p-4 lg:px-10 lg:py-6">
      <CategoryEditForm onSubmit={handleSubmit} submitting={submitting} settingCategory={settingCategory} />

      <MyAlertDialog
        open={showMyAlertDialog}
        onSuccess={onSubmit}
        onCancel={() => setShowMyAlertDialog(false)}
        description="Você editou o comportamento de manter o fornecedor conectado após aprovação nesta categoria. Ao continuar, você irá atualizar todos os fornecedores aprovados para esta configuração."
      />
    </div>
  );
}

export default CategoryEdit;
