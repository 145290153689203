import React from "react";
import { useNavigate } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";

import z from "zod";
import { REQUIRED_STRING } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Breadcrumb } from "DS/custom/breadcrumb";

import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "DS/ui/form";

import CountryCombobox from "Organisms/UBuyer/Companies/Index/AddSupplier/CountryCombobox";

const ZOD_RESOLVER = zodResolver(
  z.object({
    name: REQUIRED_STRING,
    country: REQUIRED_STRING,
    identifier: REQUIRED_STRING,
  }),
);

function AddInternationalSupplier() {
  const navigate = useNavigate();

  const form = useForm({
    resolver: ZOD_RESOLVER,
    defaultValues: { name: "", country: "", identifier: "" },
  });

  const onSubmit = ({ name, identifier, country }) => {
    const params = new URLSearchParams({ name, identifier, country });
    navigate(`form?${String(params)}`);
  };

  return (
    <div className="space-y-4">
      <Breadcrumb levels={["Fornecedores", "Iniciar homologação", "Internacional (TIN)"]} />

      <Text weigh="medium" className="mt-2">
        Digite os dados do seu fornecedor...
      </Text>

      <FormProvider {...form}>
        <form id="AddInternationalSupplier" className="flex flex-col gap-6" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex w-full max-w-xl flex-col gap-3">
              <FormField
                name="identifier"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-medium">Tax Identification Number (TIN)</FormLabel>

                    <FormControl>
                      <Input placeholder="Digite um Tax Identification Number (TIN)..." {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="name"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-medium">Company name</FormLabel>

                    <FormControl>
                      <Input placeholder="Digite o Company name..." {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="country"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-medium">País</FormLabel>

                    <FormControl>
                      <CountryCombobox {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="space-x-4">
            <Button type="submit">Selecionar fornecedor</Button>

            <Button type="button" variant="outline" onClick={() => navigate("./..")}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default AddInternationalSupplier;
