import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import NoMatch from "Pages/LandingPage/Static/NoMatch";

import Panel from "Pages/Suppliers/Panel";

import AddSupplier from "Pages/Suppliers/Companies/AddSupplier";
import AddPJSupplier from "Pages/Suppliers/Companies/AddPJSupplier";
import AddPFSupplier from "Pages/Suppliers/Companies/AddPFSupplier";
import AddPJSupplierForm from "Pages/Suppliers/Companies/AddPJSupplierForm";
import AddPFSupplierForm from "Pages/Suppliers/Companies/AddPFSupplierForm";
import AddPJSupplierManually from "Pages/Suppliers/Companies/AddPJSupplierManually";
import AddInternationalSupplier from "Pages/Suppliers/Companies/AddInternationalSupplier";
import AddPJSupplierManuallyForm from "Pages/Suppliers/Companies/AddPJSupplierManuallyForm";
import AddInternationalSupplierForm from "Pages/Suppliers/Companies/AddInternationalSupplierForm";

import SuppliersLayout from "Organisms/UBuyer/Companies/SuppliersLayout";

function SuppliersIndex() {
  return (
    <Routes>
      <Route path="" element={<SuppliersLayout />}>
        <Route path="new">
          <Route path="" element={<AddSupplier />} />

          <Route path="pj">
            <Route path="" element={<AddPJSupplier />} />
            <Route path="form" element={<AddPJSupplierForm />} />
          </Route>

          <Route path="pj-manual">
            <Route path="" element={<AddPJSupplierManually />} />
            <Route path="form" element={<AddPJSupplierManuallyForm />} />
          </Route>

          <Route path="pf">
            <Route path="" element={<AddPFSupplier />} />
            <Route path="form" element={<AddPFSupplierForm />} />
          </Route>

          <Route path="international">
            <Route path="" element={<AddInternationalSupplier />} />
            <Route path="form" element={<AddInternationalSupplierForm />} />
          </Route>
        </Route>
      </Route>

      <Route path=":id" element={<Navigate to="panel" replace />} />
      <Route path=":id/panel" element={<Panel />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default SuppliersIndex;
