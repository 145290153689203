import React from "react";

import PropTypes from "prop-types";

import { Pencil } from "lucide-react";
import { Text } from "DS/custom/text";
import { DropdownMenuItem } from "DS/ui/dropdown-menu";

function EditFormMenuItem({ buyerId, settingFormId }) {
  return (
    <DropdownMenuItem
      onClick={() => window.location.assign(`/buyers/${buyerId}/settings/fields/supplier_fields/${settingFormId}/edit`)}
    >
      <Text color="secondary">
        <Pencil strokeWidth={3} className="mr-2 h-4 w-4 " />
      </Text>

      <Text weight="medium" color="secondary">
        Editar
      </Text>
    </DropdownMenuItem>
  );
}

EditFormMenuItem.propTypes = {
  buyerId: PropTypes.string.isRequired,
  settingFormId: PropTypes.string.isRequired,
};

export default EditFormMenuItem;
