import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import { useToast } from "DS/ui/use-toast";

import { parseFields } from "Config/SettingForm";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import FormNewForm from "Organisms/UBuyer/Settings/Forms/FormNewForm";

/** @internal */
export const MUTATION = gql`
  mutation FormNew(
    $buyerId: ID!
    $hint: String
    $name: String
    $fields: JSON!
    $expirable: Boolean!
    $displayName: String!
    $settingGrouperId: ID!
    $triggerWebhookAfterExpiredReview: Boolean!
  ) {
    createSettingForm(
      input: {
        name: $name
        hint: $hint
        fields: $fields
        buyerId: $buyerId
        expirable: $expirable
        displayName: $displayName
        settingGrouperId: $settingGrouperId
        triggerWebhookAfterExpiredReview: $triggerWebhookAfterExpiredReview
      }
    ) {
      settingForm {
        id
        settingGrouper {
          id
          settingDocuments {
            id
            displayName
          }
        }
        buyer {
          id
          settingForms {
            id
            displayName
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function FormNew() {
  const { toast } = useToast();
  const { buyerId } = useParams();
  const [createSettingForm, { loading }] = useMutation(MUTATION);

  const onSubmit = async (value) => {
    const fields = parseFields(value.fields);
    const variables = { ...value, buyerId, fields };
    const { data } = await createSettingForm({ variables });
    const { errors } = data.createSettingForm;

    if (errors.length) {
      toast({
        variant: "destructive",
        description: errors[0].message,
        title: "Erro ao criar o formulário",
      });
    } else {
      toast({ title: "Formulário criado com sucesso" });
      window.location = `/buyers/${buyerId}/settings/fields/supplier_fields`;
    }
  };

  return (
    <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
      <Breadcrumb
        lastPage="Documentos"
        lastPageUrl={`/buyers/${buyerId}/settings/fields/supplier_fields`}
        currentPage="Novo documento"
      />

      <FormNewForm onSubmit={onSubmit} submitting={loading} />
    </div>
  );
}

export default FormNew;
