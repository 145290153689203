import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import z from "zod";
import { useForm } from "react-hook-form";
import { REQUIRED_STRING, OPTIONAL_STRING, REQUIRED_NUMBER } from "Config/Zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "DS/ui/input";
import { Text } from "DS/custom/text";
import { Button } from "DS/ui/button";
import { Textarea } from "DS/ui/textarea";

import OptionsFormField from "Organisms/UBuyer/Settings/BuyerDocuments/OptionsFormField";
import DocumentRiskSelect from "Organisms/UBuyer/Settings/BuyerDocuments/DocumentRiskSelect";
import { WrappedTooltip } from "DS/custom/wrapped-tooltip";

import { FORM_FIELD_TYPE_OPTIONS, SELECT } from "Config/BuyerDocument";

import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "DS/ui/accordion";

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormDescription } from "DS/ui/form";

import { Select, SelectItem, SelectGroup, SelectValue, SelectTrigger, SelectContent } from "DS/ui/select";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";

const ZOD_SCHEMA = z.object({
  hint: OPTIONAL_STRING,
  risk: REQUIRED_NUMBER,
  name: OPTIONAL_STRING,
  fieldType: REQUIRED_STRING,
  displayName: REQUIRED_STRING,
  options: z.array(REQUIRED_STRING).optional(),
});

function FormEditForm({ onSubmit, submitting, formInitialValues }) {
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(ZOD_SCHEMA),
    defaultValues: formInitialValues,
  });

  const options = form.watch("options");
  const fieldType = form.watch("fieldType");

  useEffect(() => {
    form.setValue("options", fieldType === SELECT ? formInitialValues.options : []);
  }, [fieldType, form, formInitialValues]);

  return (
    <Form {...form}>
      <form
        id="FormEditForm"
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 p-4 lg:px-10 lg:py-6"
      >
        <div className="flex flex-row items-center justify-between gap-8">
          <Breadcrumb
            firstPage="Solicitados ao comprador"
            lastPage={formInitialValues.displayName}
            currentPage="Editar"
          />

          <div className="flex flex-row gap-4">
            <Button form="FormEditForm" type="submit" disabled={submitting}>
              Salvar
            </Button>

            <Button type="button" variant="outline" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
          </div>
        </div>

        <div>
          <div className="space-y-4 border-b border-slate-100 pb-5">
            <FormField
              control={form.control}
              name="displayName"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium" htmlFor="displayName">
                      Título do documento
                    </FormLabel>

                    <FormControl>
                      <Input id="displayName" placeholder="Insira o nome deste documento..." {...field} />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hint"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium" htmlFor="hint">
                      Descrição{" "}
                      <Text size="small" color="secondary" as="span">
                        (opcional)
                      </Text>
                    </FormLabel>

                    <FormDescription>
                      Este texto fica abaixo do nome de exibição do formulário, e serve como descrição para um
                      entendimento correto do campo.
                    </FormDescription>

                    <FormControl>
                      <Textarea
                        id="hint"
                        className="h-40 min-h-[80px]"
                        placeholder="Insira dicas de como preencher corretamente este documento..."
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="risk"
              render={() => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormLabel className="font-medium" htmlFor="risk">
                      Pontuação
                    </FormLabel>

                    <FormControl>
                      <DocumentRiskSelect
                        className="h-9 w-full"
                        doc={{}}
                        risk={String(form.watch("risk"))}
                        onSelectRisk={(newRisk) => form.setValue("risk", Number(newRisk))}
                      />
                    </FormControl>

                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-8">
                  <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                    <FormControl>
                      <Accordion type="single" collapsible>
                        <AccordionItem value="form" className="border-0">
                          <AccordionTrigger className="font-medium text-blue-600">
                            Configurações avançadas
                          </AccordionTrigger>
                          <AccordionContent>
                            <FormLabel className="font-medium" htmlFor="hint">
                              Código de integração{" "}
                              <Text size="small" color="secondary" as="span">
                                (opcional)
                              </Text>
                            </FormLabel>

                            <FormDescription>
                              Esse é o código que representa esse documento na utilização da API.
                            </FormDescription>

                            <Input className="w-full p-2" id="name" {...field} />
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </FormControl>
                  </div>
                </FormItem>
              )}
            />

            <div className="w-full max-w-lg border-t border-t-slate-200 pt-4">
              <div className="w-full max-w-lg rounded-md border border-slate-200 p-4">
                <FormField
                  control={form.control}
                  name="fieldType"
                  render={({ field }) => (
                    <FormItem className="flex flex-row gap-8">
                      <div className="flex w-full max-w-lg flex-col gap-2 text-sm">
                        <FormLabel className="font-medium">Tipo da resposta</FormLabel>

                        <FormControl>
                          <WrappedTooltip>
                            <Select id="fieldType" defaultValue={fieldType} onValueChange={field.onChange}>
                              <SelectTrigger>
                                <SelectValue placeholder="Selecione um tipo de resposta" />
                              </SelectTrigger>

                              <SelectContent>
                                <SelectGroup>
                                  {FORM_FIELD_TYPE_OPTIONS.map(({ label, value, icon }) => (
                                    <SelectItem key={value} value={value}>
                                      <div className="flex flex-row items-center gap-2">
                                        {icon}
                                        {label}
                                      </div>
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </WrappedTooltip>
                        </FormControl>

                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                {fieldType === SELECT ? <OptionsFormField options={options} control={form.control} /> : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}

FormEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formInitialValues: PropTypes.shape({
    hint: PropTypes.string,
    displayName: PropTypes.string,
    risk: PropTypes.number,
    fieldType: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default FormEditForm;
