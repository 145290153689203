import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { Button } from "DS/ui/button";
import { Text } from "DS/custom/text";
import { useToast } from "DS/ui/use-toast";
import Skeleton from "DS/custom/skeleton";

import Breadcrumb from "Organisms/UBuyer/Insights/Breadcrumb";
import FormSortableForms from "Organisms/UBuyer/Settings/Forms/FormSortableForms";

/** @internal */
export const QUERY = gql`
  query FormsListReorder($buyerId: ID!) {
    buyer: buyerById(id: $buyerId) {
      id
      settingForms {
        id
        displayName
        settingGrouper {
          id
          displayName
        }
      }
    }
  }
`;

/** @internal */
export const MUTATION = gql`
  mutation FormsListReorder($settingFormIds: [ID!]!, $buyerId: ID!) {
    reorderSettingForms(input: { settingFormIds: $settingFormIds, buyerId: $buyerId }) {
      buyer {
        id
        settingForms {
          id
        }
      }
    }
  }
`;

const SKELETON = (
  <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
    <div className="flex flex-row items-center justify-between gap-x-8 gap-y-4">
      <Skeleton className="h-6 w-80" />
      <Skeleton className="h-10 w-44" />
    </div>
    <Skeleton className="h-6 w-52" />
    <Skeleton className="h-16 w-[543px]" />
    <Skeleton className="h-6 w-96" repeat={6} />
  </div>
);

function FormsListReorder() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { buyerId } = useParams();
  const [settingForms, setSettingForms] = useState([]);
  const onReorderForms = (forms) => setSettingForms(forms);
  const [reorderSettingForms, { loading: submitting }] = useMutation(MUTATION);

  const { loading } = useQuery(QUERY, {
    variables: { buyerId },
    onCompleted: ({ buyer }) => setSettingForms(buyer.settingForms),
  });

  if (loading) return SKELETON;

  const onClick = async () => {
    const settingFormIds = settingForms.map(({ id }) => id);

    await reorderSettingForms({ variables: { buyerId, settingFormIds } });

    toast({ title: "Formulários reordenados com sucesso!" });
    navigate("./..");
  };

  return (
    <div className="flex flex-col gap-6 p-4 lg:px-10 lg:py-6">
      <div className="flex flex-row items-center justify-between gap-x-8 gap-y-4">
        <Breadcrumb lastPage="Documentos" currentPage="Alterar ordem de apresentação" />

        <div className="flex flex-row items-center gap-4">
          <Button type="button" onClick={onClick} disabled={submitting}>
            Salvar
          </Button>

          <Button type="button" variant="outline" disabled={submitting} onClick={() => navigate("./..")}>
            Cancelar
          </Button>
        </div>
      </div>

      <div className="flex max-w-3xl flex-col gap-1">
        <Text weight="medium">Ordenamento dos campos</Text>

        <Text>
          Você pode alterar a ordem em que os documentos irão aparecer para preenchimento pelo fornecedor. A regra de
          quais documentos serão solicitados não é definida aqui, mas sim dentro da categoria.
        </Text>
      </div>

      <FormSortableForms settingForms={settingForms} onReorderForms={onReorderForms} />
    </div>
  );
}

export default FormsListReorder;
