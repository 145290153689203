import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Tracker from "Services/Tracker";
import RequireAuth from "Services/RequireAuth";
import RedirectUser from "Services/RedirectUser";

// Users
import Login from "Pages/Login";
import SsoCallback from "Pages/User/SsoCallback";

import SupplierSignup from "Pages/User/SupplierSignup";
import BuyerSignup from "Pages/User/BuyerSignup";
import UserPasswordChange from "Pages/User/UserPasswordChange";

// Static
import NoMatch from "Pages/LandingPage/Static/NoMatch";
import NetworkError from "Pages/LandingPage/Static/NetworkError";

// Public
import PerformanceForm from "Pages/PerformanceForm";

// BuyerAccount
import Suppliers from "Pages/Suppliers";

// Skynet
import MainContainerBuyer from "Organisms/Shared/MainContainer/MainContainerBuyer";

import Settings from "Pages/Settings";

function Router() {
  const location = useLocation();

  useEffect(() => {
    Tracker.setup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Tracker.page();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route path="/" element={<RedirectUser />} />

      <Route exact path="/companies" element={<RedirectUser />} />

      <Route path="login/*" element={<Login />} />

      <Route path="sso-callback" element={<SsoCallback />} />

      <Route path="modificar-senha" element={<UserPasswordChange />} />

      <Route path="signup/:token" element={<SupplierSignup />} />

      <Route path="user-invite/:token" element={<BuyerSignup />} />

      <Route path="performance-form/:token" element={<PerformanceForm />} />

      <Route element={<MainContainerBuyer />}>
        <Route path="companies/*" element={<RequireAuth Component={<Suppliers />} />} />
      </Route>

      <Route path="settings/*" element={<RequireAuth Component={<Settings />} />} />

      <Route path="network-error" element={<NetworkError />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
